import React from "react";
import logo from "@assets/images/lr-logo---footer.png";

const Policy = () => {
  const date = new Date();

  return (
    <div className="policy_container">
      <img src={logo} style={{ marginBottom: "10px" }} alt="logo" />
      <p className="bottom-margin">
        © JAGUAR LAND ROVER LIMITED {date.getFullYear()}
      </p>

      <p className="bottom-margin">
        28 Victoria Link, Route 21 Corporate Park, Nellmapius Drive,
        <br />
        Irene X30, Centurion, Tshwane, Gauteng,
        <br /> South Africa
      </p>

      <p className="bottom-margin">
        Smart Settings is intended to be released as part of a future wireless
        software update. Software development and releases are subject to
        movements in planning and programming, and dates may be subject to
        change.
      </p>
      <p className="bottom-margin">
        All derivatives of Defender and Velar are available to order now. Please
        refer to your retailer for more detail on customer deliveries of Plug-in
        Hybrid models when placing your order.
      </p>
      <p className="bottom-margin">
        “Recommended Retail Prices shown includes VAT, CO2 Tax , registration
        and Retailer fitting costs (if required). Recommended Retail Prices are
        subject to change without notice and include a 5 year/100,000km
        Maintenance Plan and Roadside Assistance. Vehicles in retailer stock
        might differ from the website. Please consult your local Retailer for
        stock vehicle specifications. Standard Terms and Conditions apply.
        Vehicle standard features and options reflect current model year
        specifications“.
      </p>
      <p className="bottom-margin">
        <b>Important note on imagery & specification. </b>The global shortage of
        semiconductors is currently affecting vehicle build specifications,
        option availability, and build timings. This is a very dynamic
        situation, and as a result imagery used within the website at present
        may not fully reflect current specifications for features, options, trim
        and colour schemes. Please consult your Retailer who will be able to
        confirm any current restrictions with you in order to allow an informed
        choice.
      </p>
      <p className="bottom-margin">
        Jaguar Land Rover Limited is constantly seeking ways to improve the
        specification, design and production of its vehicles, parts and
        accessories and alterations take place continually, and we reserve the
        right to change without notice. Some features may vary between optional
        and standard for different model years. The information, specification,
        engines and colours on this website are based on European specification
        and may vary from market to market and are subject to change without
        notice. Some vehicles are shown with optional equipment and retailer-fit
        accessories that may not be available in all markets. Please contact
        your local retailer for local availability and prices.
      </p>
      <p className="bottom-margin">
        The mapping on this website is provided by external mapping providers
        and is for general information purposes only.
      </p>
    </div>
  );
};

export default Policy;
