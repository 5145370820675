import React, { useState } from "react";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Typography,
} from "@mui/material";

const steps = [
  {
    label: "Confirmation Call",
    description: `Land Rover Bedfordview will contact you to finalise the details of your purchase.`,
  },
  {
    label: "Offer to purchase",
    description:
      "you will receive your personalised offer to purchase shortly via email",
  },
  {
    label: "Payment",
    description: `Once you have signed your offer to purchase, the payment process will be initiated.`,
  },
  {
    label: "Vehicle Prepartion",
    description:
      "Your vehicle will be arranged and transported to your selected",
  },
];

const StepperComponent = () => {
  const [activeStep, setActiveStep] = useState(1);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        orientation={window.innerWidth > 600 ? "horizontal" : "vertical"}
      >
        {steps?.length > 0 &&
          steps.map((label) => (
            <Step key={label}>
              <StepLabel>
                {label.label}
                <br />
                <span> {label.description}</span>
              </StepLabel>
              {/* <StepContent> */}
              {/* <StepLabel>{label.description}</StepLabel> */}
              {/* </StepContent> */}
            </Step>
          ))}
      </Stepper>
    </Box>
  );
};

export default StepperComponent;
