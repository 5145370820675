import Navbar from "@components/NavMenu";
import { Box, Typography } from "@mui/material";
import React from "react";
import { NavLink, useNavigate } from "react-router-dom";

const ThankYou = () => {
  const navigate = useNavigate();
  return (
    <div>
      {" "}
      <Navbar navLogo={""} />
      <Box padding={8} paddingBottom={12}>
        <Typography
          variant="h5"
          sx={{
            marginLeft: "12px",
            // fontSize: "24px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          Thank You For Order
        </Typography>
        <Box mt={3}>
          <Typography
            variant="h5"
            sx={{ marginLeft: "12px", textAlign: "center" }}
          >
            Your order been placed!
          </Typography>
        </Box>
        <Box
          mt={1}
          padding={2}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <span style={{ fontSize: "16px", lineHeight: "2rem" }}>
            Your order has been successfully processed.
            <br />
            Yo can view your order history by going to the{" "}
            <NavLink style={{ fontSize: "14px" }} to={"/dashboard"}>
              my account
            </NavLink>{" "}
            page and clicking by{" "}
            <NavLink style={{ fontSize: "14px" }} to={"/dashboard"}>
              my ordered vehicles.
            </NavLink>
            <br />
            For inquiry, please contact our{" "}
            <NavLink style={{ fontSize: "14px" }} to={"/contact-us"}>
              customer support team
            </NavLink>
            <br />
            Thanks for shopping with us online!
          </span>
        </Box>
        <Box display={"flex"} justifyContent={"center"} mt={2}>
          <button style={{ width: "53%" }} onClick={() => navigate("/")}>
            Continue
          </button>
        </Box>
      </Box>
    </div>
  );
};

export default ThankYou;
