import React, { useState } from "react";
import "./style.css";
import { NavLink, useNavigate } from "react-router-dom";
import logo from "../../assets/images/lr-logo---footer.png";
import { useEffect } from "react";

const menuItems = [
  {
    title: "NEW VEHICLES",
    url: "https://buy.landrover.co.za",
    cName: "nav-links",
  },
  // {
  //   title: "PURCHASE",
  //   url: "#",
  //   cName: "nav-links",
  // },
  // {
  //   title: "OWNERS",
  //   url: "#",
  //   cName: "nav-links",
  // },
  // {
  //   title: "EXPLORE",
  //   url: "#",
  //   cName: "nav-links",
  // },
];

const NavMenu = () => {
  const [active, setActive] = useState(false);
  const [navLogo, setNavLogo] = useState("");
  const navigate = useNavigate();
  const cookiesData = sessionStorage.getItem("item_key");
  let data = sessionStorage.getItem("userDetails");
  const userData = data ? JSON.parse(data) : data;
  useEffect(() => {
    if (cookiesData === "range-rover") {
      setNavLogo("RANGE ROVER");
    } else if (cookiesData === "defender") {
      setNavLogo("DEFENDER");
    } else if (cookiesData === "discovery") {
      setNavLogo("DISCOVERY");
    }
  }, [cookiesData]);
  const handleClick = () => {
    setActive(!active);
  };
  const handleMenu = (i) => {
    if (i === 0) {
      window.open("https://buy.landrover.co.za/", "_self");
    }
  };
  const handleLoginRoute = () => {
    navigate("/dashboard");
  };
  return (
    <>
      {/* Old navbar */}
      {/* <nav className="navbar-container d-flex justify-content-between align-items-center">
        <div className="menu-icon" onClick={handleClick}>
          <i className={active ? "fas fa-times" : "fas fa-bars"}></i>
        </div>
        <ul className={active ? "nav-menu-new active" : "nav-menu-new"}>
          {menuItems.map((item, index) => {
            return (
              <li key={index}>
                <a href={item.url} className={item.cName}>
                  {item.title}
                </a>
              </li>
            );
          })}
        </ul>
        <div>
          <img src={RangeRoverLogo} className="nav-logo" width="200px" />
        </div>

        <div className="nav-button-group d-flex justify-content-between">
          <i className="fa-solid fa-magnifying-glass nav-buttons nav-search"></i>
          <i className="fa-solid fa-location-dot nav-buttons nav-location"></i>
        </div>
      </nav> */}

      {/* New navbar */}
      <nav className="navbar-container-new">
        <div className="main-logo-container">
          <img src={logo} className="main-logo" onClick={() => navigate("/")} />

          <div className="menu-icon" onClick={handleClick}>
            <i className={active ? "fas fa-times" : "fas fa-bars"}></i>
          </div>
          <ul className={active ? "nav-menu-new active" : "nav-menu-new"}>
            {menuItems.map((item, index) => {
              return (
                <li key={item.title} onClick={(e) => handleMenu(index)}>
                  <NavLink to={item.url} className={item.cName}>
                    {item.title}
                  </NavLink>
                </li>
              );
            })}
          </ul>
        </div>
        <div onClick={() => navigate("/")}>
          {navLogo && <h5 className="nav-logo-new">{navLogo}</h5>}
        </div>

        <div className="nav-button-group-new">
          <i
            className="fa-regular fa-user nav-buttons"
            onClick={handleLoginRoute}
          ></i>
        </div>
      </nav>
    </>
  );
};

export default NavMenu;
