import React from "react";
import { Grid, Box, Modal, Typography } from "@mui/material";
import { RxCross2 } from "react-icons/rx";
import styles from "./RepresentativePopup.module.css";
import StepperComponent from "@components/Stepper";

const StepperPopup = ({ open, close, featureData }) => {
  return (
    <div>
      <Modal
        open={open}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={styles.stepper_container}>
          <Box display={"flex"} justifyContent={"space-between"}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Order Process
            </Typography>
            <Box fontSize={"24px"} sx={{ cursor: "pointer" }} onClick={close}>
              <RxCross2 />
            </Box>
          </Box>
          <hr style={{ width: "100%" }} />
          <Grid
            item
            xs={12}
            sx={{ mt: 4 }}
            display={"flex"}
            width={"100%"}
            gap={"15%"}
          >
            <Grid
              item
              xs={12}
              width={"100%"}
              className={styles.feature_content}
            >
              <StepperComponent />
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

export default StepperPopup;
