import React from "react";
import Menu from "./Menu";
import Policy from "./Policy";
import Navbar from "./Navbar";
import "./style.css";

const Footer = () => {
  return (
    <>
      <div style={{ width: "100%" }}>
        <Menu />
        <Navbar />
        <Policy />
      </div>
    </>
  );
};

export default Footer;
