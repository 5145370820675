import React, { useState } from "react";

const ExploreButton = ({ children, style, ...props }) => {
  const [color, setColor] = useState("white");
  const buttonStyle = {
    padding: "0.5rem 1rem",
    fontSize: "0.8rem",
    backgroundColor: color === "white" ? "#0c121c" : "#fff",
    color: color === "#0c121c" ? "#000" : "white",
    border: "1px solid #4a4f54",
    cursor: "pointer",
    transition: "background-color 0.3s",
    borderRadius: "0",
    width: "10%",
    height: "10%",
    ...style,
  };

  return (
    <button
      style={buttonStyle}
      onMouseOver={() => setColor("#0c121c")}
      onMouseOut={() => setColor("white")}
      {...props}
    >
      {children}
    </button>
  );
};

export default ExploreButton;
