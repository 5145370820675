import React from "react";
import { Grid, Box, Modal, Typography } from "@mui/material";
import ExploreButton from "../ExploreButton";
import { BsArrowRight } from "react-icons/bs";
import { RxCross2 } from "react-icons/rx";
import styles from "./RepresentativePopup.module.css";

const RepresentativePopup = ({ open, close }) => {
  return (
    <div>
      <Modal
        open={open}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={styles.modal_container}>
          <Box display={"flex"} justifyContent={"space-between"}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              REPRESENTIVE EXAMPLE
            </Typography>
            <Box fontSize={"24px"} sx={{ cursor: "pointer" }} onClick={close}>
              <RxCross2 />
            </Box>
          </Box>
          <hr />
          <Grid
            item
            xs={12}
            sx={{ mt: 4 }}
            display={"flex"}
            width={"100%"}
            gap={"15%"}
          >
            <Grid item xs={4} width={"40%"}>
              <Typography sx={{ mt: 2 }} fontSize={"12px"}>
                This is a representative example of the vehicle price. The final
                price may change based on approved vehicle financing,
                accessories and value-added products that may be included.
              </Typography>
            </Grid>
            <Grid
              item
              xs={8}
              width={"40%"}
              backgroundColor={"#f8f9f9"}
              padding={2}
            >
              <Box display={"flex"} justifyContent={"space-between"}>
                <Typography variant="paragraph" sx={{ mt: 2 }}>
                  Deposite
                </Typography>
                <Typography fontWeight={"bold"} sx={{ mt: 2 }}>
                  10%
                </Typography>
              </Box>
              <hr />
              <Box display={"flex"} justifyContent={"space-between"}>
                <Typography variant="paragraph" sx={{ mt: 2 }}>
                  Interest rate
                </Typography>
                <Typography sx={{ mt: 2 }} fontWeight={"bold"}>
                  11.75%
                </Typography>
              </Box>
              <hr />
              <Box display={"flex"} justifyContent={"space-between"}>
                <Typography variant="paragraph" sx={{ mt: 2 }}>
                  Payment terms
                </Typography>
                <Typography fontWeight={"bold"} sx={{ mt: 2 }}>
                  72 months
                </Typography>
              </Box>
              <hr />
              <Box display={"flex"} justifyContent={"space-between"}>
                <Typography variant="paragraph" sx={{ mt: 2 }}>
                  Ballon
                </Typography>
                <Typography fontWeight={"bold"} sx={{ mt: 2 }}>
                  25%
                </Typography>
              </Box>

              <ExploreButton
                className={styles.detail_button}
                style={{
                  ...btnStyle,
                  ...(window.innerWidth <= 600
                    ? buttonStyleForSmallDevice
                    : {}),
                }}
                onClick={close}
              >
                <BsArrowRight size={15} /> CLOSE
              </ExploreButton>
            </Grid>
          </Grid>
          <hr />
          <Typography fontSize={"9px"}>
            All calculations made using the calculator on this site are
            estimates only. The final amounts are subject to confirmation at the
            time of finalising any transactions. Product, fee and price
            information used in the calculator are subject to change at any time
            prior to a final quote being issued to the user. The user
            indemnifies Land Rover Finance against any loss or liability which
            the user may suffer as a result of the use of this calculator. All
            applications are subject to credit approval from Land Rover Finance.
            The calculated instalment includes the initiation fee of R1207.50
            and a monthly service fee of R69. Terms and Conditions apply and
            finance subject to approval by Land Rover Finance, a product of
            WesBank - a division of FirstRand Bank Ltd. An Authorised Finance
            and Registered Credit Provider. NCRCP20.
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};
const buttonStyleForSmallDevice = {
  height: "24%",
  width: "24%",
};

const btnStyle = {
  fontSize: "0.7rem",
  fontWeight: "600",
  letterSpacing: "0.2em",
  float: "right",
  marginTop: "25px",
};

export default RepresentativePopup;
