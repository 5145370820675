import React from "react";
import { Grid, Box, Modal, Typography } from "@mui/material";
import { RxCross2 } from "react-icons/rx";
import styles from "./RepresentativePopup.module.css";
import { FiCheck } from "react-icons/fi";

const DescriptionPopup = ({ open, close, featureData }) => {
  return (
    <div>
      <Modal
        open={open}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={styles.description_container}>
          <Box display={"flex"} justifyContent={"space-between"}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              FEATURE DESCRIPTION
            </Typography>
            <Box fontSize={"24px"} sx={{ cursor: "pointer" }} onClick={close}>
              <RxCross2 />
            </Box>
          </Box>
          <hr />
          <Grid
            item
            xs={12}
            // sx={{ mt: 4 }}
            display={"flex"}
            width={"100%"}
            gap={"15%"}
          >
            <Grid
              item
              xs={12}
              width={"100%"}
              className={styles.feature_content}
            >
              <Typography fontSize={"12px"}>
                {featureData?.length > 0 &&
                  featureData.map((value, index) => {
                    return (
                      value !== "" && (
                        <ul key={value.id}>
                          <span className={styles.vehicle_list_data}>
                            <FiCheck className={styles.check_button} />
                            <span className={styles.list_data}>{value}</span>
                          </span>
                        </ul>
                      )
                    );
                  })}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

export default DescriptionPopup;
