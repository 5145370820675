const dev = {
  // BASE_URL: "https://findnewlandrover.vnnovateserver.com/api/",
  BASE_URL: "https://findmyapiv2.jlrsaportal.co.za/api/",
  // BASE_URL: "https://udaanapi.jlrsaportal.co.za/",
};

const ENV = dev;

export default ENV;

// export const GOOGLE_MAP_API_KEY = "AIzaSyA8T5AInUbKgQwKZQMB3JuyfT56CfkhH0A";
export const GOOGLE_MAP_API_KEY = "AIzaSyCA1iMh5chouafYJga9PtEBZ2SS7qrNoSA";

// export const RECAPTCHA_SITE_KEY = "6LegiqUoAAAAAOL0_bfHmSXGaX1GYAVBEcPVdyD1";
export const RECAPTCHA_SITE_KEY = "6LcUwKsfAAAAAJ8LKOAS1qf7-wlq--gXWjDlimES";
export const RECAPTCHA_SECRET_KEY = "6LegiqUoAAAAAHmYKGs81Nn4S1Tbow8daVyunji2";
